@import '~nprogress/nprogress';

$progress-color: #ffd44d;

/* nprogress */
#nprogress .bar {
    background: $progress-color;
    height: 3px;
    z-index: 99999;
}

/* Fancy blur effect */
#nprogress .peg {
    box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    border-top-color: $progress-color;
    border-left-color: $progress-color;
}
