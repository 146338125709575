@import 'fonts';
@import 'nprogress';

// TODO: CSS Variables doesn't work with IE11, enable this once management decided to stop support for IE11
:root {
//   --clr-alizarin: #e71d36;
//   --clr-azure: #f0f6f6;
//   --clr-charcoal: #4a4a4a;
//   --clr-charcoal2: #424242;
//   --clr-cornflower-blue: #4a90e2;
//   --clr-corn-silk: #fff6db;
//   --clr-corn-silk2: #fff6da;
//   --clr-dark-gray: #a3a3a3;
//   --clr-dark-violet: #7f16bd;
//   --clr-dim-gray: #737373;
//   --clr-eminence: #824179;
//   --clr-foam: #ccebe9;
//   --clr-gainsboro: #e0e0e0;
//   --clr-gainsboro2: #d8d8d8;
//   --clr-grey: #767676;
//   --clr-grey2: #999999;
//   --clr-kournikova: #ffd44d;
//   --clr-matisse: #3b556f;
//   --clr-night-rider: #333333;
//   --clr-nobel: #979797;
//   --clr-pelorous: #2998cc;
//   --clr-persimmon: #f26201;
//   --clr-snow: #f9f9f9;
//   --clr-sunglow: #ffce34;
//   --clr-venetian-red: #b60017;
//   --clr-white: #ffffff;
    --white: #ffffff;
}

html {
    min-height: 100vh;
    position: relative;
}

body {
    font-size: 14px;
    font-weight: normal;
    color: #333333 !important;
    background-color: #ffffff !important;
    overflow-x: hidden;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

.FooterLinks__linkList {
    font-size: 14px;
}
